import React from "react";
import logo from "src/assets/logo.svg";
import closeIcon from "src/assets/close.svg";
import "./style.scss";

const About = ({ open, onClose }) => (
  <div
    className="about-modal-container"
    onClick={onClose}
    style={{ display: open ? "flex" : "none" }}
  >
    <div className="content" onClick={e => e.stopPropagation()}>
      <div className="button-div">
        <img className="logo-img" src={logo} alt="logo" />
        <div className="close-div" onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </div>
      </div>
      <div className="main-description">
        <p>
          Digital Giant is a multi-discipline imaging and production studio cofounded by friends
          and experienced industry professionals Dallas Carroll and Alex Pickup,
          working primarily out of Los Angeles and London.
        </p>
        <p>
          The Digital Giant ethos is to approach every project with freshness and adaptability,
          using our broad knowledge of production methods and processes to achieve great results
          and make things as straight forward and streamlined as is possible.
        </p>
        <p>
          In simple terms, we just love working with our fellow artists to create imagery of all kinds,
          we relish the process as much as the outcome, and we believe that the whole event should be
          fun and rewarding for everybody involved.
        </p>
      </div>
      <div className="gap" />
      <p className="get-in-touch">
        GET IN TOUCH
      </p>
      <div className="contact-div">
        <div className="one-contact">
          <p>Dallas Carrol</p>
          <p>Executive Producer</p>
          <p>info@digital-giant.com</p>
          <p>M: 949-637-7190</p>
        </div>
        <div className="one-contact">
          <p>Represented by I.M.I.</p>
          <p>Kristina Hicks</p>
          <p>info@imiartists.com</p>
          <p>M: 650-430-2230</p>
        </div>
      </div>
    </div>
  </div>
);

export default About;

import React from "react";
import Header from "src/App/components/Header";
import SubHeader from "src/App/components/SubHeader";
import "./style.scss";

const videoMP4 = "https://dc5pya1hr91fi.cloudfront.net/mobile.mp4";

const Home = ({ data, texts }) => (
  <div className="home-page">
    <div className="background-video-container">
      <video autoPlay loop muted playsInline>
        <source src={videoMP4} type="video/mp4" />
      </video>
    </div>

    { data && texts && (
      <div className="header-container">
        <Header data={data} texts={texts} />
        <SubHeader data={data} texts={texts} />
      </div>
    )}
  </div>
);

export default Home;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import nextImg from "src/assets/next.svg";
import "./style.scss";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [notFound, setNotFound] = useState(false);

  const onClickNext = () => {
    if (email) {
      setNotFound(true);
    }
  };

  return (
    <div className="reset-page">
      <div className="content">
        { notFound && (
          <p className="error-message">
            Digital Giant does not have record of this email.
            Pleaae double check your email or contact your Digital Giant Representitive.
          </p>
        )}
        <p>
          Enter Your Email To Reset Password
        </p>
        <input
          type="text"
          placeholder="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className="control-div">
          <span />
          <img src={nextImg} alt="next" onClick={onClickNext} />
        </div>
      </div>
    </div>
  );
};

export default Reset;

import columns from "./columns.json";

export const wordInterval = 4500;

export const randomNumber = n => Math.floor(Math.random() * n);

export const getRandomText = () => {
  const characters = "  __?-#!^*~ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 10 + randomNumber(20);

  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(randomNumber(characters.length));
  }
  return result;
};

export const getSubHeaderText = () => columns
  .map(texts => texts[randomNumber(texts.length)])
  .join(" ");

export const getWeatherTexts = (weather) => {
  const weatherId = weather.daily[0].weather[0].id;
  const mainId = Math.floor(weatherId / 100);

  // Group 2xx: Thunderstorm
  if (mainId === 2) {
    return [
      "Try not to go golfing tomorrow, okay?",
      "Looks like thunderstorms are coming.",
    ];
  }

  // Group 3xx: Drizzle
  if (mainId === 3) {
    return [
      "Find a good book and better blanket.",
      "Tomorrow calls for drizzle, for-rizzle.",
    ];
  }

  // Group 5xx: Rain
  if (mainId === 5) {
    return [
      "Where did you leave your umbrella last?",
      "You should find it before tomorrow’s rain.",
    ];
  }

  // Group 6xx: Snow
  if (mainId === 6) {
    return [
      "Did you know snow is translucent, not white?",
      "You should check with tomorrow’s snowfall.",
    ];
  }

  // Group 7xx: Atmosphere
  if (mainId === 7) {
    return [
      "Have you ever really listened to the wind?",
      "It’s a big part of tomorrow’s weather, give a try.",
    ];
  }

  // Group 800: Clear
  if (weatherId === 800) {
    const options = [
      [
        "You should think about getting sunscreen.",
        "The skies will be crisp and clear tomorrow.",
      ],
      [
        "Did you know clouds can block UV rays?",
        "Too bad they aren’t in your forecast tomorrow.",
      ],
      [
        "So you know, the sky is not blue.",
        "Ponder that tomorrow with your clear skies.",
      ],
      [
        "Open your mind, you can see for miles",
        "Thanks to the clear skies in your forecast tomorrow.",
      ],
    ];
    return options[randomNumber(options.length)];
  }

  // Group 80x: Clouds
  if (mainId === 8) {
    const options = [
      [
        "Would you like to learn more about clouds?",
        "You’ll have plenty of time to look at them tomorrow.",
      ],
      [
        "Cumulus, Stratus and Cirrus are all cloud types.",
        "Learn about them, because they’ll be around tomorrow.",
      ],
      [
        "Do you think clouds are weightless?",
        "They’re not, and they’ll be in your sky tomorrow.",
      ],
      [
        "Keep on your toes, Nephele is coming to visit.",
        "But it just may be the weather calling for clouds tomorrow.",
      ],
    ];
    return options[randomNumber(options.length)];
  }

  // Return empty array
  return [];
};

import React, { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import { wordInterval } from "src/App/utils";
import "./style.scss";

const Header = ({ data, texts }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    // First
    const hour = new Date().getHours();
    if (hour < 12) {
      setText(`Good Morning ${data.ip}`);
    } else if (hour < 17) {
      setText(`Good Afternoon ${data.ip}`);
    } else {
      setText(`Good Evening ${data.ip}`);
    }

    // Second
    setTimeout(() => {
      setText("Digital Giant");
    }, wordInterval * texts.length);
  }, []);

  return (
    <div className="header">
      <TextTransition
        text={text}
        springConfig={presets.wobbly}
      />
    </div>
  );
};

export default Header;
